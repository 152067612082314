import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsOverviewTraineeProfile,
} from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsOverviewTraineeProfilesSlice } from 'store/slices';

/**
 * A custom hook to handle Charts OverviewTraineeProfiles REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsOverviewTraineeProfile>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsOverviewTraineeProfiles - array of adminChartsOverviewTraineeProfiles in the RestRootState reducer
 * @returns reqAdminChartsOverviewTraineeProfiles - makes requests and stores results in the adminChartsOverviewTraineeProfilesReducer
 * @returns getErrorsOverviewTraineeProfiles - gets API errors from the adminChartsOverviewTraineeProfilesReducer
 */
export function useAdminChartsOverviewTraineeProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<
  IChart<IChartsOverviewTraineeProfile>,
  RestRootState,
  RootState
>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsOverviewTraineeProfile>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsOverviewTraineeProfile>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsOverviewTraineeProfiles',
    actions: adminChartsOverviewTraineeProfilesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsOverviewTraineeProfiles: hook.getResults,
    reqAdminChartsOverviewTraineeProfiles: hook.request,
    getErrorsAdminChartsOverviewTraineeProfiles: hook.getErrors,
    hasResultsAdminChartsOverviewTraineeProfiles: hook.hasResults,
    getMessageAdminChartsOverviewTraineeProfiles: hook.getMessage,
    getCodeAdminChartsOverviewTraineeProfiles: hook.getCode,
    isLoadingAdminChartsOverviewTraineeProfiles: hook.isLoading,
    isErrorAdminChartsOverviewTraineeProfiles: hook.isError,
    isSuccessAdminChartsOverviewTraineeProfiles: hook.isSuccess,
  };
}
