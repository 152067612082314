import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsGoal } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsGoalsSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Goals REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsGoal>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsGoals - array of adminChartsGoals in the RestRootState reducer
 * @returns reqAdminChartsGoals - makes requests and stores results in the adminChartsGoalsReducer
 * @returns getErrorsGoals - gets API errors from the adminChartsGoalsReducer
 */
export function useAdminChartsGoals({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsGoal>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsGoal>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsGoal>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsGoals',
    actions: adminChartsGoalsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsGoals: hook.getResults,
    reqAdminChartsGoals: hook.request,
    getErrorsAdminChartsGoals: hook.getErrors,
    hasResultsAdminChartsGoals: hook.hasResults,
    getMessageAdminChartsGoals: hook.getMessage,
    getCodeAdminChartsGoals: hook.getCode,
    isLoadingAdminChartsGoals: hook.isLoading,
    isErrorAdminChartsGoals: hook.isError,
    isSuccessAdminChartsGoals: hook.isSuccess,
  };
}
