import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IProgramProfile } from '@guider-global/shared-types';
import { adminProgramProfilesSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle ProgramProfile REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IProgramProfile> hook
 * @returns ...hook - all return values via the `useRestRedux<IProgramProfile>()` hook
 * @returns adminProgramProfiles - array of adminProgramProfiles in the RestRootState reducer
 * @returns reqAdminProgramProfiles - makes requests and stores results in the adminProgramProfilesReducer
 * @returns getErrorsPrograms - gets API errors from the adminProgramProfilesReducer
 */
export function useAdminProgramProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IProgramProfile, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IProgramProfile, RestRootStateTypes> =
    useRestRedux<IProgramProfile, RestRootState, RestRootStateTypes, RootState>(
      {
        ...args,
        resultDataName: 'adminProgramProfiles',
        actions: adminProgramProfilesSlice.actions,
        waitForAuthentication,
        accessToken,
        onExpiredAccessToken: getAccessToken,
        traceId: sessionId ?? '',
      },
    );
  return {
    ...hook,
    adminProgramProfiles: hook.getResults,
    reqAdminProgramProfiles: hook.request,
    getErrorsAdminProgramProfiles: hook.getErrors,
    hasResultsAdminProgramProfiles: hook.hasResults,
    getMessageAdminProgramProfiles: hook.getMessage,
    getCodeAdminProgramProfiles: hook.getCode,
    isLoadingAdminProgramProfiles: hook.isLoading,
    isErrorAdminProgramProfiles: hook.isError,
    isSuccessAdminProgramProfiles: hook.isSuccess,
  };
}
