import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsOverviewGuideProfile,
} from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsOverviewGuideProfilesSlice } from 'store/slices';

/**
 * A custom hook to handle Charts OverviewGuideProfile REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsOverviewGuideProfile>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsOverviewGuideProfile - array of adminChartsOverviewGuideProfile in the RestRootState reducer
 * @returns reqAdminChartsOverviewGuideProfile - makes requests and stores results in the adminChartsOverviewGuideProfileReducer
 * @returns getErrorsOverviewGuideProfile - gets API errors from the adminChartsOverviewGuideProfileReducer
 */
export function useAdminChartsOverviewGuideProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<
  IChart<IChartsOverviewGuideProfile>,
  RestRootState,
  RootState
>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsOverviewGuideProfile>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsOverviewGuideProfile>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsOverviewGuideProfiles',
    actions: adminChartsOverviewGuideProfilesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsOverviewGuideProfiles: hook.getResults,
    reqAdminChartsOverviewGuideProfiles: hook.request,
    getErrorsAdminChartsOverviewGuideProfiles: hook.getErrors,
    hasResultsAdminChartsOverviewGuideProfiles: hook.hasResults,
    getMessageAdminChartsOverviewGuideProfiles: hook.getMessage,
    getCodeAdminChartsOverviewGuideProfiles: hook.getCode,
    isLoadingAdminChartsOverviewGuideProfiles: hook.isLoading,
    isErrorAdminChartsOverviewGuideProfiles: hook.isError,
    isSuccessAdminChartsOverviewGuideProfiles: hook.isSuccess,
  };
}
