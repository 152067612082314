import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramConcludedRelationship,
} from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsProgramConcludedRelationshipsSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Program Concluded Relationships REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsProgramConcludedRelationship>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsProgramConcludedRelationships - array of adminChartsProgramConcludedRelationships in the RestRootState reducer
 * @returns reqAdminChartsProgramConcludedRelationships - makes requests and stores results in the adminChartsProgramConcludedRelationshipsReducer
 * @returns getErrorsAdminChartsProgramConcludedRelationships - gets API errors from the adminChartsProgramConcludedRelationshipsReducer
 */
export function useAdminChartsProgramConcludedRelationships({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<
  IChart<IChartsProgramConcludedRelationship>,
  RestRootState,
  RootState
>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsProgramConcludedRelationship>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsProgramConcludedRelationship>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsProgramConcludedRelationships',
    actions: adminChartsProgramConcludedRelationshipsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsProgramConcludedRelationships: hook.getResults,
    reqAdminChartsProgramConcludedRelationships: hook.request,
    getErrorsAdminChartsProgramConcludedRelationships: hook.getErrors,
    hasResultsAdminChartsProgramConcludedRelationships: hook.hasResults,
    getMessageAdminChartsProgramConcludedRelationships: hook.getMessage,
    getCodeAdminChartsProgramConcludedRelationships: hook.getCode,
    isLoadingAdminChartsProgramConcludedRelationships: hook.isLoading,
    isErrorAdminChartsProgramConcludedRelationships: hook.isError,
    isSuccessAdminChartsProgramConcludedRelationships: hook.isSuccess,
  };
}
