import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsSignupsOverTime } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsSignupsSlice } from 'store/slices';

/**
 * A custom hook to handle SIChartsSignupsOverTime REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<ISIChartsSignupsOverTime> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsSignups - array of adminChartsSignups in the RestRootState reducer
 * @returns reqAdminChartsSignups - makes requests and stores results in the adminChartsSignupsReducer
 * @returns getErrorsSignups - gets API errors from the adminChartsSignupsReducer
 */
export function useAdminChartsSignups({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsSignupsOverTime>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsSignupsOverTime>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsSignupsOverTime>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsSignups',
    actions: adminChartsSignupsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsSignups: hook.getResults,
    reqAdminChartsSignups: hook.request,
    getErrorsAdminChartsSignups: hook.getErrors,
    hasResultsAdminChartsSignups: hook.hasResults,
    getMessageAdminChartsSignups: hook.getMessage,
    getCodeAdminChartsSignups: hook.getCode,
    isLoadingAdminChartsSignups: hook.isLoading,
    isErrorAdminChartsSignups: hook.isError,
    isSuccessAdminChartsSignups: hook.isSuccess,
  };
}
