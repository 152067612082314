import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IProfileProgramOverview } from '@guider-global/shared-types';
import { adminProfileProgramOverviewsSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IProfileProgramOverview>()` hook
 * @returns adminProfileProgramOverviews - array of adminProfileProgramOverviews in the RestRootState reducer
 * @returns reqAdminProfileProgramOverviews - makes requests and stores results in the adminProfileProgramOverviewsReducer
 * @returns getErrorsUsers - gets API errors from the adminProfileProgramOverviewsReducer
 */
export function useAdminProfileProgramOverviews({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IProfileProgramOverview, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IProfileProgramOverview, RestRootStateTypes> =
    useRestRedux<
      IProfileProgramOverview,
      RestRootState,
      RestRootStateTypes,
      RootState
    >({
      ...args,
      resultDataName: 'adminProfileProgramOverviews',
      actions: adminProfileProgramOverviewsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    adminProfileProgramOverviews: hook.getResults,
    reqAdminProfileProgramOverviews: hook.request,
    getErrorsAdminProfileProgramOverviews: hook.getErrors,
    hasResultsAdminProfileProgramOverviews: hook.hasResults,
    getMessageAdminProfileProgramOverviews: hook.getMessage,
    getCodeAdminProfileProgramOverviews: hook.getCode,
    isLoadingAdminProfileProgramOverviews: hook.isLoading,
    isErrorAdminProfileProgramOverviews: hook.isError,
    isSuccessAdminProfileProgramOverviews: hook.isSuccess,
  };
}
