import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramTraineeProfile,
} from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsProgramTraineeProfilesSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Program Trainee Profiles REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsProgramTraineeProfile>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsProgramTraineeProfiles - array of adminChartsProgramTraineeProfiles in the RestRootState reducer
 * @returns reqAdminChartsProgramTraineeProfiles - makes requests and stores results in the adminChartsProgramTraineeProfilesReducer
 * @returns getErrorsAdminChartsProgramTraineeProfiles - gets API errors from the adminChartsProgramTraineeProfilesReducer
 */
export function useAdminChartsProgramTraineeProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<
  IChart<IChartsProgramTraineeProfile>,
  RestRootState,
  RootState
>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsProgramTraineeProfile>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsProgramTraineeProfile>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsProgramTraineeProfiles',
    actions: adminChartsProgramTraineeProfilesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsProgramTraineeProfiles: hook.getResults,
    reqAdminChartsProgramTraineeProfiles: hook.request,
    getErrorsAdminChartsProgramTraineeProfiles: hook.getErrors,
    hasResultsAdminChartsProgramTraineeProfiles: hook.hasResults,
    getMessageAdminChartsProgramTraineeProfiles: hook.getMessage,
    getCodeAdminChartsProgramTraineeProfiles: hook.getCode,
    isLoadingAdminChartsProgramTraineeProfiles: hook.isLoading,
    isErrorAdminChartsProgramTraineeProfiles: hook.isError,
    isSuccessAdminChartsProgramTraineeProfiles: hook.isSuccess,
  };
}
