import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsSkillsOffered } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsSkillsOfferedSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Skills REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsSkillsOffered>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsSkills - array of adminChartsSkills in the RestRootState reducer
 * @returns reqAdminChartsSkills - makes requests and stores results in the adminChartsSkillsReducer
 * @returns getErrorsSkills - gets API errors from the adminChartsSkillsReducer
 */
export function useAdminChartsSkillsOffered({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsSkillsOffered>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication: true,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsSkillsOffered>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsSkillsOffered>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsSkillsOffered',
    actions: adminChartsSkillsOfferedSlice.actions,
    waitForAuthentication,
    accessToken,
    traceId: sessionId ?? '',
    onExpiredAccessToken: getAccessToken,
  });
  return {
    ...hook,
    adminChartsSkillsOffered: hook.getResults,
    reqAdminChartsSkillsOffered: hook.request,
    getErrorsAdminChartsSkillsOffered: hook.getErrors,
    hasResultsAdminChartsSkillsOffered: hook.hasResults,
    getMessageAdminChartsSkillsOffered: hook.getMessage,
    getCodeAdminChartsSkillsOffered: hook.getCode,
    isLoadingAdminChartsSkillsOffered: hook.isLoading,
    isErrorAdminChartsSkillsOffered: hook.isError,
    isSuccessAdminChartsSkillsOffered: hook.isSuccess,
  };
}
