import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramGuideProfile,
} from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsProgramGuideProfilesSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Program Guide Profiles REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsProgramGuideProfile>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsProgramGuideProfiles - array of adminChartsProgramGuideProfiles in the RestRootState reducer
 * @returns reqAdminChartsProgramGuideProfiles - makes requests and stores results in the adminChartsProgramGuideProfilesReducer
 * @returns getErrorsAdminChartsProgramGuideProfiles - gets API errors from the adminChartsProgramGuideProfilesReducer
 */
export function useAdminChartsProgramGuideProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<
  IChart<IChartsProgramGuideProfile>,
  RestRootState,
  RootState
>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsProgramGuideProfile>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsProgramGuideProfile>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsProgramGuideProfiles',
    actions: adminChartsProgramGuideProfilesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsProgramGuideProfiles: hook.getResults,
    reqAdminChartsProgramGuideProfiles: hook.request,
    getErrorsAdminChartsProgramGuideProfiles: hook.getErrors,
    hasResultsAdminChartsProgramGuideProfiles: hook.hasResults,
    getMessageAdminChartsProgramGuideProfiles: hook.getMessage,
    getCodeAdminChartsProgramGuideProfiles: hook.getCode,
    isLoadingAdminChartsProgramGuideProfiles: hook.isLoading,
    isErrorAdminChartsProgramGuideProfiles: hook.isError,
    isSuccessAdminChartsProgramGuideProfiles: hook.isSuccess,
  };
}
