import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsUser } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsUsersSlice } from 'store/slices';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsUsers - array of adminChartsUsers in the RestRootState reducer
 * @returns reqAdminChartsUsers - makes requests and stores results in the adminChartsUsersReducer
 * @returns getErrorsUsers - gets API errors from the adminChartsUsersReducer
 */
export function useAdminChartsUsers({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsUser>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsUser>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsUser>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsUsers',
    actions: adminChartsUsersSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsUsers: hook.getResults,
    reqAdminChartsUsers: hook.request,
    getErrorsAdminChartsUsers: hook.getErrors,
    hasResultsAdminChartsUsers: hook.hasResults,
    getMessageAdminChartsUsers: hook.getMessage,
    getCodeAdminChartsUsers: hook.getCode,
    isLoadingAdminChartsUsers: hook.isLoading,
    isErrorAdminChartsUsers: hook.isError,
    isSuccessAdminChartsUsers: hook.isSuccess,
  };
}
