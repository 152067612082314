import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsSkillsSought } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsSkillsSoughtSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Skills REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsSkillsSought>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsSkills - array of adminChartsSkills in the RestRootState reducer
 * @returns reqAdminChartsSkills - makes requests and stores results in the adminChartsSkillsReducer
 * @returns getErrorsSkills - gets API errors from the adminChartsSkillsReducer
 */
export function useAdminChartsSkillsSought({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsSkillsSought>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication: true,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsSkillsSought>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsSkillsSought>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsSkillsSought',
    actions: adminChartsSkillsSoughtSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsSkillsSought: hook.getResults,
    reqAdminChartsSkillsSought: hook.request,
    getErrorsAdminChartsSkillsSought: hook.getErrors,
    hasResultsAdminChartsSkillsSought: hook.hasResults,
    getMessageAdminChartsSkillsSought: hook.getMessage,
    getCodeAdminChartsSkillsSought: hook.getCode,
    isLoadingAdminChartsSkillsSought: hook.isLoading,
    isErrorAdminChartsSkillsSought: hook.isError,
    isSuccessAdminChartsSkillsSought: hook.isSuccess,
  };
}
