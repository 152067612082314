import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { KPIs } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminKPIsProgramSlice } from 'store/slices';

/**
 * A custom hook to handle Program KPIs REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<KPIs> hook
 * @returns ...hook - all return values via the `useRestRedux<KPIs>()` hook
 * @returns adminKPIsProgram - array of adminKPIsProgram in the RestRootState reducer
 * @returns reqAdminKPIsProgram - makes requests and stores results in the adminKPIsProgramReducer
 * @returns getErrorsAdminKPIsProgram - gets API errors from the adminKPIsProgramReducer
 */
export function useAdminKPIsProgram({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<KPIs, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<KPIs, RestRootStateTypes> = useRestRedux<
    KPIs,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminKPIsProgram',
    actions: adminKPIsProgramSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminKPIsProgram: hook.getResults,
    reqAdminKPIsProgram: hook.request,
    getErrorsAdminKPIsProgram: hook.getErrors,
    hasResultsAdminKPIsProgram: hook.hasResults,
    getMessageAdminKPIsProgram: hook.getMessage,
    getCodeAdminKPIsProgram: hook.getCode,
    isLoadingAdminKPIsProgram: hook.isLoading,
    isErrorAdminKPIsProgram: hook.isError,
    isSuccessAdminKPIsProgram: hook.isSuccess,
  };
}
