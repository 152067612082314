import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IChart, IChartsProgramGoal } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminChartsProgramGoalsSlice } from 'store/slices';

/**
 * A custom hook to handle Charts Program Goals REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<<IChart<IChartsProgramGoal>> hook
 * @returns ...hook - all return values via the `useRestRedux<IChart>()` hook
 * @returns adminChartsProgramGoals - array of adminChartsProgramGoals in the RestRootState reducer
 * @returns reqAdminChartsProgramGoals - makes requests and stores results in the adminChartsProgramGoalsReducer
 * @returns getErrorsAdminChartsProgramGoals - gets API errors from the adminChartsProgramGoalsReducer
 */
export function useAdminChartsProgramGoals({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IChart<IChartsProgramGoal>, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<
    IChart<IChartsProgramGoal>,
    RestRootStateTypes
  > = useRestRedux<
    IChart<IChartsProgramGoal>,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminChartsProgramGoals',
    actions: adminChartsProgramGoalsSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminChartsProgramGoals: hook.getResults,
    reqAdminChartsProgramGoals: hook.request,
    getErrorsAdminChartsProgramGoals: hook.getErrors,
    hasResultsAdminChartsProgramGoals: hook.hasResults,
    getMessageAdminChartsProgramGoals: hook.getMessage,
    getCodeAdminChartsProgramGoals: hook.getCode,
    isLoadingAdminChartsProgramGoals: hook.isLoading,
    isErrorAdminChartsProgramGoals: hook.isError,
    isSuccessAdminChartsProgramGoals: hook.isSuccess,
  };
}
