import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IProgramDetails } from '@guider-global/shared-types';
import { adminProgramsSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IProgramDetails>()` hook
 * @returns adminPrograms - array of adminPrograms in the RestRootState reducer
 * @returns reqAdminPrograms - makes requests and stores results in the adminProgramsReducer
 * @returns getErrorsUsers - gets API errors from the adminProgramsReducer
 */
export function useAdminPrograms({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IProgramDetails, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IProgramDetails, RestRootStateTypes> =
    useRestRedux<IProgramDetails, RestRootState, RestRootStateTypes, RootState>(
      {
        ...args,
        resultDataName: 'adminPrograms',
        actions: adminProgramsSlice.actions,
        waitForAuthentication,
        accessToken,
        onExpiredAccessToken: getAccessToken,
        traceId: sessionId ?? '',
      },
    );
  return {
    ...hook,
    adminPrograms: hook.getResults,
    reqAdminPrograms: hook.request,
    getErrorsAdminPrograms: hook.getErrors,
    hasResultsAdminPrograms: hook.hasResults,
    getMessageAdminPrograms: hook.getMessage,
    getCodeAdminPrograms: hook.getCode,
    isLoadingAdminPrograms: hook.isLoading,
    isErrorAdminPrograms: hook.isError,
    isSuccessAdminPrograms: hook.isSuccess,
  };
}
