import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMatch } from '@guider-global/shared-types';
import { adminMatchesSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

export function useAdminMatches({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMatch, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMatch, RestRootStateTypes> = useRestRedux<
    IMatch,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminMatches',
    actions: adminMatchesSlice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminMatches: hook.getResults,
    reqAdminMatches: hook.request,
    getErrorsAdminMatches: hook.getErrors,
    hasResultsAdminMatches: hook.hasResults,
    getMessageAdminMatches: hook.getMessage,
    getCodeAdminMatches: hook.getCode,
    isLoadingAdminMatches: hook.isLoading,
    isErrorAdminMatches: hook.isError,
    isSuccessAdminMatches: hook.isSuccess,
  };
}
