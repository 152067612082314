import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { OrganizationIntegration } from '@guider-global/shared-types';
import { organizationIntegrationsSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

export function useOrganizationIntegrations({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<OrganizationIntegration, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<OrganizationIntegration, RestRootStateTypes> =
    useRestRedux<
      OrganizationIntegration,
      RestRootState,
      RestRootStateTypes,
      RootState
    >({
      ...args,
      resultDataName: 'organizationIntegrations',
      actions: organizationIntegrationsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    organizationIntegrations: hook.getResults,
    reqOrganizationIntegrations: hook.request,
    getErrorsOrganizationIntegrations: hook.getErrors,
    hasResultsOrganizationIntegrations: hook.hasResults,
    getMessageOrganizationIntegrations: hook.getMessage,
    getCodeOrganizationIntegrations: hook.getCode,
    isLoadingOrganizationIntegrations: hook.isLoading,
    isErrorOrganizationIntegrations: hook.isError,
    isSuccessOrganizationIntegrations: hook.isSuccess,
  };
}
